import React, { Component } from 'react';
import HomeBanner from './HomeBanner';
import HomeFeaturedBets from './HomeFeaturedBets';
import './styles/Home.css';

class Home extends Component {

  render() {
    return (
      <div>
        <HomeBanner />
        <HomeFeaturedBets />
      </div>
      
    );
  }
}

export default Home;