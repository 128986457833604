import React, { Component } from 'react';


class ConfirmationModal extends Component {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose(e) {
    this.props.closeHandler();
  }

  render() {
    // console.log(this.props)
    return (
      <div className={"modal " + (this.props.open ? "is-active" : "")}>
        <div className="modal-background" onClick={this.handleClose}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{this.props.success ? 'Congratulations!' : 'Uh-oh!' }</p>
            <button className="delete" aria-label="close" onClick={this.handleClose}></button>
          </header>
          <section className="modal-card-body">
            {this.props.success ? 'Your order has been successfully cancelled.' 
            : 'Something went wrong, and your order cancellation was unsuccessful.'}

          </section>
          <footer className="modal-card-foot">
            <button className="button is-success" onClick={this.handleClose}>Done</button>
          </footer>
        </div>
      </div>
    )
  }
}

export default ConfirmationModal;
