import React, { Component } from 'react';
import { Link } from "react-router-dom";

class HomeBanner extends Component {
  render() {
    return (
        <section className="hero banner is-info is-medium">
        <div className="hero-body">
          <div className="container">
            <h1 className="title is-1">
              Betiver
            </h1>
            <Link to="/politics">
              <div className="button is-outlined is-white ">
                Go to markets
              </div>
            </Link>
          </div>
        </div>
      </section>
    );
  }
}

export default HomeBanner;