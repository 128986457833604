import React, { Component } from 'react';
import HeaderBrand from './HeaderBrand';
import HeaderUserControls from './HeaderUserControls';

import { NavLink } from "react-router-dom";

// import Amplify, { API } from 'aws-amplify';
// let apiName = 'ptrade';


class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMenuActive: false
    }

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState({isMenuActive: !this.state.isMenuActive});
    // console.log(!this.state.isMenuActive);
  }

  render() {
    return (
      <header>
        <nav className="navbar has-shadow ">
          <div className="container">
            <HeaderBrand toggleMenu={this.toggleMenu} />

            <div className={"navbar-menu container " + (this.state.isMenuActive ? "is-active" : "")} >
              <div className="navbar-start">
                {/* <NavLink to="/sports" activeClassName="hurray" className="navbar-item">
                  This is the sports market
                </NavLink> */}
                <NavLink to="/politics/us-elections" activeClassName="hurray" className="navbar-item">
                  US Elections
                </NavLink>
                <NavLink to="/politics/trump-admin" activeClassName="hurray" className="navbar-item">
                  Trump Admin
                </NavLink>
                <NavLink to="/politics/world" activeClassName="hurray" className="navbar-item">
                  World
                </NavLink>
                <NavLink to="/politics/others" activeClassName="hurray" className="navbar-item">
                  Others
                </NavLink>
              </div>
              <HeaderUserControls  user={this.props.user} signOut={this.props.signOut}/>
            </div>
          </div>
          
        </nav>
        { /*<div>


        {"USER INFO"}
        <p>
          {"Username: " + this.props.user.username}
        </p>
        <p>
          {"Email: " + this.props.user.email}
        </p>
        <p>
          {"Margin Available: " + this.props.user.marginAvailable}
        </p>
        <p>
          {"Estimated Account Worth: "}
        </p>
      
        <Link to="/">
          <button className="ui button">Go to the Home Page</button>
        </Link>
        <Link to="/tradepage">
          <button className="ui button">Go to the Trade Page</button>
        </Link>
        <Link to="/sportsmarket">
          <button className="ui button">Go to the Sports Market</button>
        </Link>
        <Link to="/politicalmarket">
          <button className="ui button">Go to the Political Market</button>
      </Link>
      </div>
      */}
      </header>
      
    )
  }
}

export default Header;
