import React, { Component } from 'react';
import './styles/TradeBar.css';


class TradeBar extends Component {
  constructor(props) {
    super(props);
    this.handleVolumeTextChange = this.handleVolumeTextChange.bind(this);
    this.handlePriceTextChange = this.handlePriceTextChange.bind(this);
    // this.handleTypeChange = this.handleTypeChange.bind(this);
    // this.handleDirectionChange = this.handleDirectionChange.bind(this);
    this.handleMarketType = this.handleMarketType.bind(this);
    this.handleLimitType = this.handleLimitType.bind(this);
    this.handleBuyDirection = this.handleBuyDirection.bind(this);
    this.handleSellDirection = this.handleSellDirection.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleVolumeTextChange(e) {
    this.props.volumeTextChangeHandler(e.target.value);
  }

  handlePriceTextChange(e) {
    this.props.priceTextChangeHandler(e.target.value);
  }

  // handleTypeChange(e, data) {
  //   let newType = e.target.value;
  //   this.props.typeChangeHandler(newType.toLowerCase());
  // }
  //
  // handleDirectionChange(e, data) {
  //   let newDir = e.target.value;
  //   this.props.directionChangeHandler(newDir.toLowerCase());
  // }

  handleMarketType() {
    this.props.marketTypeHandler()
  }

  handleLimitType() {
    this.props.limitTypeHandler()
  }

  handleBuyDirection() {
    this.props.buyDirectionHandler()
  }

  handleSellDirection() {
    this.props.sellDirectionHandler()
  }

  handleClick(e) {
    e.preventDefault();
    this.props.clickHandler(e.target.value);
  }

  render() {
    // console.log("input " + (this.props.volumeError ? "is-danger" : ""))

    // <label className="label">Order Type</label>
    // <div class="field has-addons">
    //   <p class="control">
    //     <a class="button">
    //       <span>Market</span>
    //     </a>
    //   </p>
    //   <p class="control">
    //     <a class="button">
    //       <span>Limit</span>
    //     </a>
    //   </p>
    // </div>
    // <div className="field" >
    //   <label className="label">Order Type</label>
    //   <div className="control">
    //     <div className="select">
    //       <select onChange={this.handleTypeChange}>
    //         <option >Market</option>
    //         <option>Limit</option>
    //       </select>
    //     </div>
    //   </div>
    // </div>
    //
    //
    //
    // <div className="field">
    //   <label className="label">Order Direction</label>
    //   <div className="control">
    //     <div className="select">
    //       <select onChange={this.handleDirectionChange}>
    //         <option >Buy</option>
    //         <option >Sell</option>
    //       </select>
    //     </div>
    //   </div>
    // </div>
    return (
      <div>

        <label className="label">Order Type</label>
        <div class="tabs is-toggle is-fullwidth">
          <ul>
            <li className={(this.props.orderType === 'market') ? 'is-active' : ''} onClick={this.handleMarketType}>
              <a>
                <span>Market</span>
              </a>
            </li>
            <li className={(this.props.orderType === 'limit') ? 'is-active' : ''} onClick={this.handleLimitType}>
              <a>
                <span>Limit</span>
              </a>
            </li>
          </ul>
        </div>

        <label className="label">Order Direction</label>
        <div class="tabs is-toggle is-fullwidth">
          <ul>
            <li className={(this.props.direction === 'buy') ? 'is-active' : ''} onClick={this.handleBuyDirection}>
              <a>
                <span>Buy</span>
              </a>
            </li>
            <li className={(this.props.direction === 'sell') ? 'is-active' : ''} onClick={this.handleSellDirection}>
              <a>
                <span>Sell</span>
              </a>
            </li>
          </ul>
        </div>

        <div className="field">
          <label className="label">Quantity</label>
          <div className="control">
            <input className={this.props.volumeError ? "input is-danger" : "input"}
              type="text" placeholder="Enter quantity"
              value={this.props.volumeText}
              onChange={this.handleVolumeTextChange} />
          </div>
          {this.props.volumeError ?
            <p class="help is-danger">Please enter a whole number.</p>
            : null
          }
        </div>

        {(this.props.orderType === 'limit') ?
          <div className="field">
            <label className="label">Limit Price</label>
            <div className="control">
              <input className={this.props.priceError ? "input is-danger" : "input"}
                type="text"
                placeholder="Enter limit price"
                value={this.props.priceText}
                onChange={this.handlePriceTextChange} />
            </div>
            {this.props.priceError ?
              <p class="help is-danger">Please enter a 2-digit decimal number between 0 and 1.</p>
              : null
            }
          </div>
        : null}


        <div className="control">
          <button className="button is-info" onClick={this.handleClick}>Submit</button>
        </div>

      </div>


    )
  }
}

export default TradeBar;
