// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "betiver-dev-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://betiver-dev-dev.s3-website-us-east-1.amazonaws.com",
    "aws_cognito_identity_pool_id": "us-east-1:e6228155-6757-4ae3-b475-04ef7ab80771",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_6UPaiHHgV",
    "aws_user_pools_web_client_id": "3t62f32b5r1p6ntomb9sgbnmdd",
    "oauth": {}
};


export default awsmobile;
