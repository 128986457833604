var config = {
    // // OPTIONAL - if your API requires authentication 
    // Auth: {
    //     // REQUIRED - Amazon Cognito Identity Pool ID
    //     identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
    //     // REQUIRED - Amazon Cognito Region
    //     region: 'XX-XXXX-X', 
    //     // OPTIONAL - Amazon Cognito User Pool ID
    //     userPoolId: 'XX-XXXX-X_abcd1234', 
    //     // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    //     userPoolWebClientId: 'a1b2c3d4e5f6g7h8i9j0k1l2m3',
    // },
    API: {
        endpoints: [
            {
                name: "ptrade",
                endpoint: "https://vfzpfna6e3.execute-api.us-east-1.amazonaws.com/prod"
            }
        ]
    }
};

export default config;