import React, { Component } from 'react';



class UserProfileHeading extends Component {

  render() {
    return (
      <section className="section notification">
        <nav className="level ">
          <div className="level-item has-text-centered is-large">
            <div>
              <h1 className="title">{"Hi, " + this.props.user.givenName + " " + this.props.user.familyName + "!"}</h1>
              {/* <div className="button is-outlined">Edit Preferences</div> */}
            {/* 
              <div class="modal is-active">
                <div class="modal-background"></div>
                <div class="modal-card">
                  <header class="modal-card-head">
                    <p class="modal-card-title">Modal title</p>
                    <button class="delete" aria-label="close"></button>
                  </header>
                  <section class="modal-card-body">
                    
                  </section>
                  <footer class="modal-card-foot">
                    <button class="button is-success">Save changes</button>
                    <button class="button">Cancel</button>
                  </footer>
                </div>
              </div>
            */}
              
            </div>
          </div>
          
        
        
          <div className="level-item has-text-centered is-large">
            <div>
              <p className="heading">Estimated Account Worth</p>
              <p className="title">{'$' + this.props.user.netWorth}</p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Margin Available</p>
              <p className="title">{'$' + this.props.user.marginAvailable}</p>
            </div>
          </div>
        </nav>
      </section>
    )
  }
}

export default UserProfileHeading;
