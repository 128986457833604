var political_events = [
        {
          event_id: 3,
          category: "us-elections",
          title: "Who will win the 2020 Presidential election?",
          topQuestions: [
            {
              q_id: 5,
              name: "Trump"
            },
            {
              q_id: 8,
              name: "Biden",
            },
            {
              q_id: 9,
              name: "Sanders",
            }
          ]
        },
        {
          event_id: 2,
          category: "us-elections",
          title: "Who will win the 2020 Republican nomination?",
          topQuestions: [
            {
              q_id: 4,
              name: "Trump",
            },
            {
              q_id: 6,
              name: "Pence",
            },
            {
              q_id: 7,
              name: "Haley",
            }
          ]
        },
        {
          event_id: 1,
          category: "us-elections",
          title: "Who will win the 2020 Democratic nomination?",
          topQuestions: [
            {
              q_id: 1,
              name: "Bernie"
            },
            {
              q_id: 2,
              name: "Kamala"
            },
            {
              q_id: 3,
              name: "Biden"
            },
          ]
        },
        {
          event_id: 4,
          category: "trump-admin",
          title: "How many tweets will @realDonaldTrump post from noon Mar. 25 to noon Apr. 1?",
          topQuestions: [
            {
              q_id: 10,
              name: "105 or more"
            },
            {
              q_id: 11,
              name: "80 to 104"
            },
            {
              q_id: 12,
              name: "79 or fewer"
            },
          ]
        },
        {
          event_id: 5,
          category: "trump-admin",
          title: "Who will be next Senate-confirmed U.N. Ambassador?",
          topQuestions: [
            {
              q_id: 13,
              name: "Kelly Craft"
            },
            {
              q_id: 14,
              name: "John James"
            },
            {
              q_id: 15,
              name: "Richard Grenell"
            },
          ]
        },
        {
          event_id: 6,
          category: "trump-admin",
          title: "Who will be Trump's next Supreme Court nominee?",
          topQuestions: [
            {
              q_id: 16,
              name: "Amy Coney Barrett"
            },
            {
              q_id: 17,
              name: "Thomas Hardiman"
            },
            {
              q_id: 18,
              name: "Amul Thapar"
            },
          ]
        },
        {
          event_id: 7,
          category: "world",
          title: "Will the UK announce another Brexit referendum",
          topQuestions: [
            {
              q_id: 19,
              name: "By March 31, 2019"
            },
            {
              q_id: 20,
              name: "By April 20, 2019"
            }
          ]
        },
        {
          event_id: 8,
          category: "world",
          title: "Official Brexit by March 29?",
          topQuestions: [
            {
              q_id: 21,
              name: "Brexit by 3/29"
            },
          ]
        },
        {
          event_id: 9,
          category: "world",
          title: "Who will be the next U.K. Conservative Party leader?",
          topQuestions: [
            {
              q_id: 22,
              name: "Michael Gove"
            },
            {
              q_id: 23,
              name: "Boris Johnson"
            },
            {
              q_id: 24,
              name: "Sajid Javid"
            },
          ]
        },

        {
          event_id: 10,
          category: "others",
          title: "How many Senate Yea votes cast for non-binding Green New Deal res by 3/31?",
          topQuestions: [
            {
              q_id: 25,
              name: "19 or fewer"
            },
            {
              q_id: 26,
              name: "20-28"
            },
            {
              q_id: 27,
              name: "29 or more"
            },
          ]
        },
        {
          event_id: 11,
          category: "others",
          title: "What will be the balance of power in Congress after the 2020 election?",
          topQuestions: [
            {
              q_id: 28,
              name: "D House, R Senate"
            },
            {
              q_id: 29,
              name: "D House, D Senate"
            },
            {
              q_id: 30,
              name: "R House, R Senate"
            },
            {
              q_id: 31,
              name: "R House, D Senate"
            },
          ]
        },
        {
          event_id: 12,
          category: "others",
          title: "Who will be the next justice to leave the Supreme Court?",
          topQuestions: [
            {
              q_id: 32,
              name: "Ruth Bader Ginsburg"
            },
            {
              q_id: 33,
              name: "Clarence Thomas"
            },
            {
              q_id: 34,
              name: "Stephen Breyer"
            },
          ]
        },

      ];

export default political_events;