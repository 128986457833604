import React, { Component } from 'react';
import UserProfileOpenOrdersCard from './UserProfileOpenOrdersCard';
import UserProfileOpenOrdersModal from './UserProfileOpenOrdersModal';
import politicalEvents from '../../political_events';

// import Authentication stuff
import Amplify, { API } from 'aws-amplify';
import awsmobile from '../../aws-exports';
import config from '../../config.js';

// configure the SDK
Amplify.configure(awsmobile);
// additional configurations like API
Amplify.configure(config);
let apiName = 'ptrade';


class UserProfileOpenOrders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      cancelSuccess: true,
      questionInfo: {}
    }

    this.handleClose = this.handleClose.bind(this);
    this.cancelOrderHandler = this.cancelOrderHandler.bind(this);
  }

  handleClose() {
    this.setState({
      showModal: false
    });
  }

  cancelOrderHandler(question, price, volume) {
    let body = {
      "q_id": parseInt(question),
      "order_type": "cancel",
      "user": this.props.user.username,
      "price": price,
      "volume": parseInt(volume)
    }

    let req = {
      headers: {'Content-Type':'application/json'},
      body: body
    }

    // console.log('request', req);

    API.post(apiName, '/maketrade', req).then(response => {
      let body = response.body;
      // console.log(body, body.Success);
      if (!(body.Success)) {
        this.setState({cancelSuccess: false})
      }
      this.setState({showModal: true});
      this.props.userUpdater();
    }).catch(error => {
      console.log(error)
    });

  }

  componentDidUpdate(prevProps) {
    if (this.props.user.openOrders !== prevProps.user.openOrders) {
      let questionInfo = {};
      for (var i=0; i<politicalEvents.length; i++) {
        let event = politicalEvents[i];
        for (var j=0; j<event.topQuestions.length; j++) {
          // console.log(event.topQuestions[j].q_id, this.props.user.openOrders)
          if (event.topQuestions[j].q_id in this.props.user.openOrders) {
            questionInfo[event.topQuestions[j].q_id] = {
              qName: event.topQuestions[j].name,
              eTitle: event.title
            }
          }
        }
      }

      this.setState({questionInfo: questionInfo});
      // console.log(questionInfo)
    }
  }

  componentDidMount() {
    let questionInfo = {};
    for (var i=0; i<politicalEvents.length; i++) {
      let event = politicalEvents[i];
      for (var j=0; j<event.topQuestions.length; j++) {
        // console.log(event.topQuestions[j].q_id, this.props.user.openOrders)
        if (event.topQuestions[j].q_id in this.props.user.openOrders) {
          questionInfo[event.topQuestions[j].q_id] = {
            qName: event.topQuestions[j].name,
            eTitle: event.title
          }
        }
      }
    }

    this.setState({questionInfo: questionInfo});
    // console.log(questionInfo)
  }

  render() {
    // console.log('questionInfo', this.state.questionInfo, this.props.user.openOrders)
    return (
      <section className="section">
        <div className="container">
          {/*<h1 className="title is-2">{this.state.event.title}</h1> */}

            {Object.keys(this.props.user.openOrders).map((question) => (
              <div key={question}>
                {Object.keys(this.state.questionInfo).length > 0 ?
                  <div>
                    <h1 className="title is-4">{this.state.questionInfo[parseInt(question)].eTitle}</h1>
                    <h1 className="title is-2">{this.state.questionInfo[parseInt(question)].qName}</h1>
                  </div>
                : null}

                <section className="section">
                  <div className="container">
                    <div className="columns is-multiline is-mobile">


                        {Object.keys(this.props.user.openOrders[question]).sort().reverse().map((order) => (
                          <div className="column is-full" key={order}>
                            <UserProfileOpenOrdersCard price={order}
                            volume={this.props.user.openOrders[question][order]}
                            handleCancelOrder={() => this.cancelOrderHandler(question, order, this.props.user.openOrders[question][order]) } />
                          </div>
                        ))}

                    </div>
                  </div>
                </section>


              </div>
            ))}

          <UserProfileOpenOrdersModal
            success={this.state.cancelSuccess}
            open={this.state.showModal}
            closeHandler={this.handleClose} />

        </div>
      </section>


    )
  }
}

export default UserProfileOpenOrders;
