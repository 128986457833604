import React, { Component } from 'react';


class ConfirmationModal extends Component {
  constructor(props) {
    super(props);

    this.listTrades = this.listTrades.bind(this);
    this.orderCount = this.orderCount.bind(this);
  }

  orderCount() {
    if (Object.keys(this.props.orderResult).length === 0) {
      return 0
    }

    let count = 0;
    Object.keys(this.props.orderResult).forEach((key) => {
      count += Math.abs(this.props.orderResult[key]);
    });
    return count;
  }

  listTrades() {
    var final = '';
    var keys = Object.keys(this.props.orderResult);
    for (var i =0; i<keys.length; i++) {
      final += this.props.orderResult[keys[i]] + " shares at " + keys[i] + ", ";
    }
    return final;
  }

  render() {
    // console.log('modal props', this.props);
    // console.log('order count', this.orderCount())
    return (
      <div>
        {(this.props.orderType === "market" && this.props.direction === "buy") ?
          <p>Congrats! Your market buy order was completed. You bought: </p> : null
        }
        {(this.props.orderType === "market" && this.props.direction === "sell") ?
          <p>Congrats! Your market sell order was completed. You sold: </p> : null
        }
        {(this.props.orderType === "limit" && this.props.direction === "buy") ?
          <p>{"Congrats! Your limit buy order was placed. So far, you bought "} 
          <strong>{this.orderCount()}</strong>{" shares at the limit price. "}
          <strong>{this.props.leftoverVolume}</strong>{" shares are still open in the market."}</p> : null
        }
        {(this.props.orderType === "limit" && this.props.direction === "sell") ?
          <p>{"Congrats! Your limit sell order was placed. So far, you sold "} 
          <strong>{this.orderCount()}</strong>{" shares at the limit price. "}
          <strong>{this.props.leftoverVolume}</strong>{" shares are still open in the market."}</p> : null
        }

        {/* list orders for market orders */}
        {((this.props.orderType === "market") && (this.orderCount() > 0)) ?
          <div className="list">
            {Object.keys(this.props.orderResult).map((price) => (
              <div className="list-item">
                <strong>{Math.abs(this.props.orderResult[price]) + " shares at " + price}</strong>
              </div>
            ))}
          </div>
          : null
        }

        <p>
          <strong>{this.props.marginCancelledVolume}</strong>
          {" orders were cancelled because they exceeded your margin available. "} 
        </p>
        <p>
          <strong>{this.props.cancelledVolume}</strong>
          {" orders were cancelled because they exceeded the available volume in the current orderbook."} 
        </p>
      </div>
    )
  }
}

export default ConfirmationModal;
