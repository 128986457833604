import React, { Component } from 'react';
import ConfirmationModalMessage from './ConfirmationModalMessage';


class ConfirmationModal extends Component {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose(e) {
    this.props.closeHandler();
  }

  render() {
    // console.log('props in ConfirmationModal', this.props);
    return (
      <div className={"modal " + (this.props.open ? "is-active" : "")}>
        <div className="modal-background" onClick={this.handleClose}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Congratulations!</p>
            <button className="delete" aria-label="close" onClick={this.handleClose}></button>
          </header>
          <section className="modal-card-body">
            <ConfirmationModalMessage 
              orderResult={this.props.orderResult} 
              orderType={this.props.orderType}
              direction={this.props.direction}
              leftoverVolume={this.props.leftoverVolume}
              cancelledVolume={this.props.cancelledVolume}
              marginCancelledVolume={this.props.marginCancelledVolume} />
          </section>
          <footer className="modal-card-foot">
            <button className="button is-success" onClick={this.handleClose}>Done</button>
          </footer>
        </div>
      </div>
    )
  }
}

export default ConfirmationModal;
