import React, { Component } from 'react';
import './styles/UserProfileAccountInfo.scss';



class UserProfileAccountInfo extends Component {

  render() {
    return (
      <section className="section">
        <div className="container columns">
          <div className="column">
            <p> <strong>{'Name: '} </strong> {this.props.user.givenName + ' ' + this.props.user.familyName} </p>
            <p> <strong>{'Email: '} </strong> {this.props.user.email} </p>
            <p> <strong>{'Username: '} </strong> {this.props.user.username} </p>
          </div>
        </div>
      </section>
    )
  }
}

export default UserProfileAccountInfo;
