import React, { Component } from 'react';
import './styles/EventQuestionCard.css';


class EventQuestionCard extends Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.clickHandler(this.props.idx);
  }

  render() {
    return (
      <div className="card " style={{ 'borderRadius': '4px'}}>
        <div className="card-content">
          <div className="media">
            <div className="media-left">
              <span className="icon is-medium has-text-info">
                <i className="fas fa-vote-yea fa-2x"></i>
              </span>
            </div>
            <div className="media-content">
              <div>
                <p className="title is-4"> {this.props.name}</p>
              </div>
            </div>
            <div className="media-right">

              <nav className="level">
                <div className="level-item has-text-centered" style={{'padding': '0.5em'}}>
                  <div>
                    <p className="heading ">Fair Value</p>
                    <p className="title is-6 has-text-success">{this.props.fairValue}</p>
                  </div>
                </div>
                <div className="button is-info" onClick={this.handleClick}>
                  Start Trading
                </div>
              </nav>

            </div>
          </div>
        </div>
        {/* <nav class="notification level is-primary">

            <div class="level-left">
            <div class="level-item">
              <p class="subtitle is-5">
                {this.props.name}
              </p>
            </div>
          </div>

          <div class="level-right">
            <div className="columns">
              <div className="column">
                <p className="is-heading">Fair Value</p>
                <p><a className="button is-info">{this.props.fairValue}</a></p>
              </div>
            </div>
          </div>

        </nav> */}
      </div>
    )
  }
}

export default EventQuestionCard;
