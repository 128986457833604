import React, { Component } from 'react';
import Orderbook from '../Orderbook/Orderbook';
import TradeBar from '../Orderbook/TradeBar';
import ConfirmationModal from '../Orderbook/ConfirmationModal'


import Amplify, { API } from 'aws-amplify';
import awsmobile from './../../aws-exports';
import config from './../../config.js';

// configure the SDK
Amplify.configure(awsmobile);
// additional configurations like API
Amplify.configure(config);
let apiName = 'ptrade';


class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderbook: {4: 5},
      volumeText: "",
      priceText: "",
      orderType: "market",
      direction: "buy",
      showModal: false,
      orderResult: {},
      leftoverVolume: 0,
      cancelledVolume: 0,
      marginCancelledVolume: 0,
      volumeError: false,
      priceError: false
    }

    this.handleClick = this.handleClick.bind(this);
    this.handleVolumeTextChange = this.handleVolumeTextChange.bind(this);
    this.handlePriceTextChange = this.handlePriceTextChange.bind(this);
    // this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleMarketType = this.handleMarketType.bind(this);
    this.handleLimitType = this.handleLimitType.bind(this);
    this.handleBuyDirection = this.handleBuyDirection.bind(this);
    this.handleSellDirection = this.handleSellDirection.bind(this);
    // this.handleDirectionChange = this.handleDirectionChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleVolumeTextChange(text) {
    this.setState({
      volumeText: text
    });
  }

  handlePriceTextChange(text) {
    this.setState({
      priceText: text
    });
  }

  // handleTypeChange(selection) {
  //   this.setState({
  //     orderType: selection
  //   });
  // }

  handleBuyDirection() {
    this.setState({
      direction: 'buy'
    });
  }

  handleSellDirection() {
    this.setState({
      direction: 'sell'
    });
  }

  handleMarketType() {
    this.setState({
      orderType: "market"
    });
  }

  handleLimitType() {
    this.setState({
      orderType: "limit"
    });
  }

  // handleDirectionChange(selection) {
  //   this.setState({
  //     direction: selection
  //   });
  // }

  handleClose() {
    this.setState({
      showModal: false
    });
  }

  validate() {
    let valid = true;
    if (!Number.isInteger(parseInt(this.state.volumeText)) || parseFloat(this.state.volumeText) - parseInt(this.state.volumeText) > 0) {
      this.setState({volumeError: true});
      valid = false;
    } else {
      this.setState({volumeError: false});
    }

    if (this.state.orderType === 'limit') {
      let float = parseFloat(this.state.priceText);
      if (isNaN(float) || float < 0 || float > 1) {
        this.setState({priceError: true});
        valid = false;
      } else {
        this.setState({priceError: false});
      }
    } else {
      this.setState({priceError: false});
    }

    return valid;

  }


  handleClick() {
    if (this.validate()) {
      let body = {
        "q_id": parseInt(this.props.qid),
        "order_type": this.state.orderType,
        "direction": this.state.direction,
        "user": this.props.username,
        "volume": parseInt(this.state.volumeText),
      }

      if (this.state.orderType === 'limit') {
        body['limit_price'] = parseFloat(this.state.priceText)
      }

      let req = {
        headers: {'Content-Type':'application/json'},
        body: body
      }

      // console.log('request', req);

      API.post(apiName, '/maketrade', req).then(response => {
        // console.log(response);
        let body = response.body;
        this.setState({showModal: true});
        this.setState({orderResult: body['order_result']});
        this.setState({leftoverVolume: body['leftover_volume']});
        this.setState({cancelledVolume: body['cancelled_volume']});
        this.setState({marginCancelledVolume: body['margin_cancelled_volume']});
        // setTimeout(this.props.userUpdater(), 10000)
        // this.props.userUpdater();
      }).catch(error => {
        console.log(error)
      });
    }
  }

  render() {
    return (
      <section className="section">

        <div className="container">
          <div className="columns ">
            <div className="column is-3">
              <div className="container">
                <h1 className="title is-3">Place Order:</h1>
              </div>
              <TradeBar
                volumeText={this.state.volumeText}
                volumeTextChangeHandler={this.handleVolumeTextChange}
                priceText={this.state.priceText}
                priceTextChangeHandler={this.handlePriceTextChange}
                marketTypeHandler={this.handleMarketType}
                limitTypeHandler={this.handleLimitType}
                buyDirectionHandler={this.handleBuyDirection}
                sellDirectionHandler={this.handleSellDirection}
                orderType={this.state.orderType}
                direction={this.state.direction}
                clickHandler={this.handleClick}
                volumeError={this.state.volumeError}
                priceError={this.state.priceError}
              />
            </div>
            <div className="column is-6">
              <Orderbook qid={this.props.qid}
                openOrders={this.props.openOrders}
                username={this.props.username}
                userUpdater={this.props.userUpdater}/>
            </div>
            <ConfirmationModal
              open={this.state.showModal}
              closeHandler={this.handleClose}
              orderType={this.state.orderType}
              direction={this.state.direction}
              orderConfirmation={this.state.orderConfirmation}
              orderResult={this.state.orderResult}
              leftoverVolume={this.state.leftoverVolume}
              cancelledVolume={this.state.cancelledVolume}
              marginCancelledVolume={this.state.marginCancelledVolume}
            />
          </div>
        </div>
      </section>
    );
  }
}

export default Question;
