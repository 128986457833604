import React, { Component } from 'react';
// IMPORT A NAV BAR FROM BULMA
// import { Table } from 'semantic-ui-react';

import Amplify, { API } from 'aws-amplify';
let apiName = 'ptrade';


class SportsMarket extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        This is the Sports Market
      </div>
    )
  }
}

export default SportsMarket;
