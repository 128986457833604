import React, { Component } from 'react';
import HomeFeaturedBetsCard from './HomeFeaturedBetsCard';
import { Link } from "react-router-dom";
import political_events from '../../political_events';

// import Authentication stuff
import Amplify, { API } from 'aws-amplify';
import awsmobile from '../../aws-exports';
import config from '../../config.js';

// configure the SDK
Amplify.configure(awsmobile);
// additional configurations like API
Amplify.configure(config);
let apiName = 'ptrade';

class HomeFeaturedBets extends Component {
  constructor(props) {
    super(props)
    this.state = {
      events: political_events,
      fairValues: {}
    }
  }

  componentDidMount() {
    // get the question IDs over all events
    
    let body = {
      "q_ids": []
    }
  
    political_events.forEach((event) => {
      event.topQuestions.forEach((question) => {
        body.q_ids.push(question.q_id)
      })
    })
    // console.log(body, body.q_ids)

    let req = {
      headers: {'Content-Type':'application/json'},
      body: body
    }

    // get the fair values for each question
    API.post(apiName, '/fairvalues', req).then(response => {
      // console.log('fair value response', response);
      this.setState({fairValues: response});
    }).catch(error => {
      console.log('error', error)
      // console.log(error.response)
    });

  }

  render() {
    return (
      <section className="section">
        <div className="container">
          <h1 className="title is-1">Featured Bets:</h1>
          <h1 className="title is-3 ">Betting markets with the highest trading activity</h1>
          <div className="columns is-multiline is-mobile">
            <div className="column is-half">
              <Link to={"/event/" + this.state.events[0].event_id}>
                <HomeFeaturedBetsCard event={this.state.events[0]} fairValues={this.state.fairValues}/>
              </Link>
            </div>
            <div className="column is-half">
              <Link to={"/event/" + this.state.events[1].event_id}>
                <HomeFeaturedBetsCard event={this.state.events[1]} fairValues={this.state.fairValues}/>
              </Link>
            </div>
            <div className="column is-half">
              <Link to={"/event/" + this.state.events[2].event_id}>
                <HomeFeaturedBetsCard event={this.state.events[2]} fairValues={this.state.fairValues}/>
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default HomeFeaturedBets;