import React, { Component } from 'react';
import MarketQuestionCard from './MarketQuestionCard';
import political_events from '../../political_events';
import { Link } from "react-router-dom";

// import Authentication stuff
import Amplify, { API } from 'aws-amplify';
import awsmobile from '../../aws-exports';
import config from '../../config.js';

// configure the SDK
Amplify.configure(awsmobile);
// additional configurations like API
Amplify.configure(config);
let apiName = 'ptrade';

let marketNames = {
  'us-elections': 'US Elections',
  'trump-admin': 'Trump Admin',
  'world': 'World',
  'others': 'Others'
}



class PoliticalMarket extends Component {
  constructor(props) {
    super(props)
    this.state = {
      events: [],
      fairValues: {},
      title: ""
    }

    this.refreshEventInfo = this.refreshEventInfo.bind(this);
  }

  refreshEventInfo() {
    // get the question IDs over all events
    let page_events = [];
    if (this.props.topic) {
      political_events.forEach((event) => {
        if (event.category === this.props.topic) {
          page_events.push(event);
        }
      });
    } else {
      page_events = political_events
    }
    // this.setState({events: page_events});
    // console.log('page_events', page_events);
    
    let body = {
      "q_ids": []
    };
  
    page_events.forEach((event) => {
      event.topQuestions.forEach((question) => {
        body.q_ids.push(question.q_id)
      })
    });
    // console.log(body, body.q_ids);

    let req = {
      headers: {'Content-Type':'application/json'},
      body: body
    };

    // get the fair values for each question
    API.post(apiName, '/fairvalues', req).then(response => {
      // console.log('fair value response', response);
      this.setState({events: page_events});
      this.setState({fairValues: response});
      if (this.props.topic) {
        this.setState({title: marketNames[this.props.topic]});
      } else {
        this.setState({title: "Political Markets"});
      }
      
    }).catch(error => {
      console.log('error', error)
      // console.log(error.response)
    });
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.topic !== prevProps.topic) {
      this.refreshEventInfo();
    }
  }

  componentDidMount() {
    this.refreshEventInfo();
  }

  render() {
    // console.log('events', this.state.events)
    return (
      <section className="section">
        <div className="container">
          <h1 className="title is-2">{this.state.title}</h1>
          <div className="columns is-multiline is-mobile">
            {this.state.events.map((event) => (
              <div className="column is-full" key={event.event_id} >
                <Link to={"/event/" + event.event_id}>
                  <MarketQuestionCard event={event} fairValues={this.state.fairValues}/>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
      
    )
  }
}

export default PoliticalMarket;
