import React, { Component } from 'react';
import '../../../node_modules/@fortawesome/fontawesome-free/css/all.css';
import './styles/Home.css';


class HomeFeaturedBets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sorted: []
    }

    this.sortByFV = this.sortByFV.bind(this);
  }

  sortByFV() {
    let sorted = this.props.event.topQuestions.slice().sort((a, b) => {
      return this.props.fairValues[b.q_id] - this.props.fairValues[a.q_id];
    });
    this.setState({sorted: sorted});
  }

  componentDidUpdate(prevProps) {
    if (this.props.fairValues !== prevProps.fairValues) {
      this.sortByFV();
    }
  }

  componentDidMount() {
    this.sortByFV();
  }

  render() {
    return (
      <div className="card grow " style={{ 'borderRadius': '5px'}} >
        <div className="card-content">
          <div className="media">
            <div className="media-left">
              <span className="icon is-medium has-text-info">
                <i className="fas fa-vote-yea fa-2x"></i>
              </span>
            </div>
            <div className="media-content">
              <div>
                <p className="title is-4"> {this.props.event.title}</p>
              </div>
            </div>
            <div className="media-right">
              {/* <div className="columns ">*/}
              <nav className="level">
                {this.state.sorted.map((question) => (
                  
                    <div className="level-item has-text-centered" style={{'padding': '0.5em'}} key={question.q_id}>
                      <div>
                        <p className="heading">{question.name}</p>
                        <p className="title is-6 has-text-success">{this.props.fairValues[question.q_id]}</p>
                      </div>
                    </div>
                  
                ))}
                {/* <div className="column has-text-centered is-half">
                    <div >
                      <p className="title is-7"> {question.name}</p>
                    </div>  
                    <p className="has-text-info"> {this.props.fairValues[question.q_id]}</p>
                  </div> */}
              </nav>
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeFeaturedBets;

