import React, { Component } from 'react';


class MarketQuestionCardQuestion extends Component {

  render() {
    console.log('fair value', this.props.fairValue);
    return (
      <div className="column">
        <p className="is-heading">{this.props.question.name}</p>
        <p><a className="button is-info">{!isNaN(this.props.fairValue) ? this.props.fairValue : ''}</a></p>
      </div>
    )
  }
}

export default MarketQuestionCardQuestion;
