import React, { Component } from 'react';
import { BrowserRouter, HashRouter, Switch, Route} from "react-router-dom";
import './App.css';

// import React components
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Home from './Home/Home.js';
import PoliticalMarket from './Market/PoliticalMarket.js';
import SportsMarket from './Market/SportsMarket';
import Question from './Question/Question';
import UserProfile from './UserProfile/UserProfile';
import Event from './Event/Event';

// import Authentication stuff
import Amplify, { API, Auth } from 'aws-amplify';
import awsmobile from '../aws-exports';
import { withAuthenticator } from 'aws-amplify-react';
import config from '../config.js';
import auth_config from '../auth_config.js';

// configure the SDK
Amplify.configure(awsmobile);
// additional configurations like API
Amplify.configure(config);
let apiName = 'ptrade';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        username: "guest",
        email: "omid.rooholfada+guest@yale.edu",
        marginAvailable: 0,
        netWorth: 0,
        givenName: "Guest",
        familyName: "Account",
        openOrders: {},
        questionPositions: {},
      }
    }

    this.updateUser = this.updateUser.bind(this);
    // this.signOut = this.signOut.bind(this);
  }


  updateUser() {
    let myInit = {
      queryStringParameters: {
        username: this.state.user.username
      }
    };

    let stateUser = this.state.user;
    // get user object from user table
    API.get(apiName, '/getuser', myInit).then(response => {
      // console.log("user object", response)
      let userInfo = response.Item
      stateUser.marginAvailable = userInfo.margin_available.toFixed(2);
      stateUser.openOrders = userInfo.open_orders;
      stateUser.questionPositions = userInfo.question_positions;

      // get user's net worth
      let myInit = {
        queryStringParameters: {
          username: this.state.user.username
        }
      };

      API.get(apiName, '/getusernetworth', myInit).then(response => {
        // console.log('net worth response', response);
        stateUser.netWorth = response.net_worth;
        this.setState({user: stateUser});
      }).catch(error => {
        console.log('error', error)
      });

    }).catch(error => {
      console.log(error)
    });
  }

  componentDidMount() {
    let myInit = {
      queryStringParameters: {
        username: this.state.user.username
      }
    };

    let stateUser = {}
    // get user object from user table
    API.get(apiName, '/getuser', myInit).then(response => {
      console.log("user object", response)
      let userInfo = response.Item
      console.log(userInfo)
      stateUser = {
        username: this.state.user.username,
        email: this.state.user.email,
        marginAvailable: userInfo.margin_available.toFixed(2),
        givenName: this.state.user.givenName,
        familyName: this.state.user.familyName,
        openOrders: userInfo.open_orders,
        questionPositions: userInfo.question_positions
      }

      // get user's net worth
      let myInit = {
        queryStringParameters: {
          username: this.state.user.username
        }
      };

      API.get(apiName, '/getusernetworth', myInit).then(response => {
        console.log('net worth response', response);
        stateUser.netWorth = response.net_worth;
        this.setState({user: stateUser});
      }).catch(error => {
        console.log('error', error)
      });

    }).catch(error => {
      console.log(error)
    });
  }

  // signOut() {
  //   Auth.signOut().then(() => {
  //     this.setState({authState: 'signIn'});
  //   }).catch(e => {
  //     console.log(e);
  //   });
  // }

  render() {
    return (
      <HashRouter>
          <div>
            <Header user={this.state.user} signOut={this.signOut}/>
            <Switch>
              <Route exact path="/" component={Home}/>
              <Route path="/trade/:q_id" render={({match}) => <Question username={this.state.user.username} qid={match.params.q_id} userUpdater={this.updateUser}/>} />
              <Route path="/event/:id" component={({match}) => <Event openOrders={this.state.user.openOrders} username={this.state.user.username} match={match} userUpdater={this.updateUser}/>} />
              <Route path="/sports" component={SportsMarket} />
              <Route path="/politics/:topic" render={({match}) => <PoliticalMarket topic={match.params.topic} /> } />
              <Route exact path="/politics" component={PoliticalMarket} />
              <Route path="/user" render={() => <UserProfile user={this.state.user} userUpdater={this.updateUser}/>} />
            </Switch>
            <Footer />
          </div>
      </HashRouter>
    );
  }
}

// withAuthenticator(App, auth_config)
export default App;
