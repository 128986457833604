import React, { Component } from 'react';
import EventQuestionCard from './EventQuestionCard';
import Question from '../Question/Question';
import political_events from '../../political_events';
// import './styles/Events.css';

import { API } from 'aws-amplify';
let apiName = 'ptrade';


class Event extends Component {
  constructor(props) {
    super(props)
    this.state = {
      event: {},
      fairValues: {},
      whichOrderbook: -1,
    }

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    // get event object
    // console.log('re-mounting');
    let current = {}
    for (var i = 0; i<political_events.length; i++) {
      if (political_events[i].event_id === parseInt(this.props.match.params.id)) {
        current = political_events[i];
        this.setState({
          event: political_events[i]
        })
        break;
      }
    }

    // get real fair values (won't be necessary later cause we'll get these w/ event object)
    let body = {
      "q_ids": []
    }

    current.topQuestions.forEach((question) => {
      body.q_ids.push(question.q_id)
    })

    let req = {
      headers: {'Content-Type':'application/json'},
      body: body
    }

    // get the fair values for each question
    API.post(apiName, '/fairvalues', req).then(response => {
      // console.log('fair value response', response);
      this.setState({fairValues: response});
    }).catch(error => {
      console.log('error', error)
      // console.log(error.response)
    });
  }

  handleClick(idx) {
    if (idx === this.state.whichOrderbook) {
      this.setState({whichOrderbook: -1});
    } else {
      this.setState({whichOrderbook: idx});
    }
  }

  render() {
    return (
      <section className="section hero is-full">
        <div className="container">
          <h1 className="title is-2">{this.state.event.title}</h1>
          <div className="columns is-multiline is-mobile">
            { Object.keys(this.state.event).length !== 0 ?
              this.state.event.topQuestions.map((question, index) => (
                <div className="column is-full" key={question.name}>

                    <EventQuestionCard
                    idx={index}
                    name={question.name}
                    qid={question.q_id}
                    fairValue={this.state.fairValues[question.q_id]}
                    clickHandler={this.handleClick} />

                  {this.state.whichOrderbook === index ?
                    <Question openOrders={this.props.openOrders} username={this.props.username} qid={question.q_id} userUpdater={this.props.userUpdater}/>
                    : null}
                </div>
            )) : null }
          </div>
        </div>
      </section>
    )
  }
}

export default Event;
