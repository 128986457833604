import React, { Component } from 'react';
import UserProfilePositionsCard from './UserProfilePositionsCard';
import politicalEvents from '../../political_events.js';


class UserProfilePositions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      positionsByEvent: {}
    }

  }

  componentDidUpdate(prevProps) {
    if (this.props.user.questionPositions !== prevProps.user.questionPositions) {
      let positionsByEvent = {};
      for (var i=0; i<politicalEvents.length; i++) {
        let event = politicalEvents[i];
        for (var j=0; j<event.topQuestions.length; j++) {
          if (event.topQuestions[j].q_id in this.props.user.questionPositions) {

            let info = {
              qName: event.topQuestions[j].name,
              position: this.props.user.questionPositions[event.topQuestions[j].q_id]
            };
            
            if (event.title in positionsByEvent) {
              positionsByEvent[event.title].push(info);
            } else {
              positionsByEvent[event.title] = [info];
            }
          }
        }
      }

      this.setState({positionsByEvent: positionsByEvent});
    }
  }

  componentDidMount() {
    let positionsByEvent = {};
    for (var i=0; i<politicalEvents.length; i++) {
      let event = politicalEvents[i];
      for (var j=0; j<event.topQuestions.length; j++) {
        if (event.topQuestions[j].q_id in this.props.user.questionPositions) {

          let info = {
            qName: event.topQuestions[j].name,
            position: this.props.user.questionPositions[event.topQuestions[j].q_id]
          };
          
          if (event.title in positionsByEvent) {
            positionsByEvent[event.title].push(info);
          } else {
            positionsByEvent[event.title] = [info];
          }
        }
      }
    }

    this.setState({positionsByEvent: positionsByEvent});
  }

  render() {
    return (
      

      <section className="section">
        <div className="container">
          
          {Object.keys(this.state.positionsByEvent).map((eventTitle) => (
            <div key={eventTitle}>
              <h1 className="title is-2">{eventTitle}</h1>
              <section className="section">
                <div className="container">
                  <div className="columns is-multiline is-mobile">
                    {this.state.positionsByEvent[eventTitle].map((question) => (
                      <div className="column is-full" key={question.qName}>
                        <UserProfilePositionsCard qName={question.qName} position={question.position} />
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            </div>
          ))}

        </div>


        {/* <section className="section">
          <div className="tile is-ancestor">
              <div className="tile is-vertical is-parent">
                {Object.keys(this.props.user.questionPositions).map((position) => (
                  <div className="notification is-primary">
                    {position + ', ' + this.props.user.questionPositions[position]}
                  </div>
                ))}
              </div>
            </div>
      </section> */}
      </section>
    )
  }
}

export default UserProfilePositions;
