import React, { Component } from 'react';
import './styles/Orderbook.css';
import UserProfileOpenOrdersModal from '../UserProfile/UserProfileOpenOrdersModal';

import Amplify, { API } from 'aws-amplify';
import config from './../../config.js';
Amplify.configure(config);
let apiName = 'ptrade';


class Orderbook extends Component {
  constructor(props) {
    super(props)
    this.state = ({
      bids: [],
      asks: [],
      showModal: false,
      cancelSuccess: true,
    })
    this.handleClose = this.handleClose.bind(this)
    this.getOrderbook = this.getOrderbook.bind(this);
    this.cancelOrderHandler = this.cancelOrderHandler.bind(this);
  }

  getOrderbook() {
    let myInit = {
      queryStringParameters: {
        q_id: this.props.qid
      }
    }

    API.get(apiName, '/getorderbook', myInit).then(response => {
      let newInfo = response.Items[0]

      // update bids based on new info
      let bids = [];
      newInfo.bid_prices.reverse();
      newInfo.bid_prices.map(price => {
        let row = {bp: price, bv: newInfo.bid_volumes[price].total_volume};
        bids.push(row)
      })
      this.setState({bids: bids});

      // update asks based on new info
      let asks = [];
      newInfo.ask_prices.reverse();
      newInfo.ask_prices.map(price => {
        let row = {ap: price, av: newInfo.ask_volumes[price].total_volume};
        asks.push(row)
      })
      this.setState({asks: asks});

    }).catch(error => {
      console.log(error)
      // console.log(error.response)
    });
    console.log(this.state.bids)
  }

  cancelOrderHandler(price, volume) {
    let body = {
      "q_id": this.props.qid,
      "order_type": "cancel",
      "user": this.props.username,
      "price": price,
      "volume": parseInt(volume)
    }

    let req = {
      headers: {'Content-Type':'application/json'},
      body: body
    }

    // console.log('request', req);

    API.post(apiName, '/maketrade', req).then(response => {
      let body = response.body;
      // console.log(body, body.Success);
      if (!(body.Success)) {
        this.setState({cancelSuccess: false})
      }
      this.setState({showModal: true});
      this.props.userUpdater();
    }).catch(error => {
      console.log(error)
    });

  }

  handleClose() {
    this.setState({
      showModal: false
    });
  }

  componentDidMount() {
    // fetch the orderbook info from database every 2 seconds

    this.getOrderbook();
    this.interval = setInterval(() => {this.getOrderbook()}, 2000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
        <div className="is-scrollable" height="200px">
          <table className="table is-striped is-hoverable is-bordered is-fullwdith is-black">

          <thead>
            <tr>
              <th>Bid Quantity</th>
              <th>Bid Price</th>
              <th>Ask Price</th>
              <th>Ask Quantity</th>
              <th>Working Orders</th>
            </tr>
          </thead>

          <tbody>

            {this.state.asks.map(row => {
              return (
                <tr key={row.ap}>
                  <td></td>
                  <td></td>
                  <td className="ask has-text-danger" >{row.ap}</td>
                  <td className="ask has-text-danger">{row.av}</td>
                  <td>{this.props.openOrders[this.props.qid] ?
                    ((row.ap in this.props.openOrders[this.props.qid]) ?
                        <div>

                          <div onClick={() => this.cancelOrderHandler(row.ap, row.av)} >
                            {Math.abs(this.props.openOrders[this.props.qid][row.ap])} (<u>Cancel</u>)
                          </div>
                        </div>
                      :null)
                  : null}

                  </td>
                </tr>
              )
            })}

            {this.state.bids.map(row => {
              return (
                <tr key={row.bp}>
                  <td className="bid has-text-success" >{row.bv}</td>
                  <td className="bid has-text-success" >{row.bp}</td>
                  <td></td>
                  <td></td>
                  <td>{this.props.openOrders[this.props.qid] ?
                    ((row.bp in this.props.openOrders[this.props.qid]) ?
                        <div>

                          <div onClick={() => this.cancelOrderHandler(row.bp, row.bv)} >
                            {Math.abs(this.props.openOrders[this.props.qid][row.bp])} (<u>Cancel</u>)
                          </div>
                        </div>
                      :null)
                  : null}

                  </td>
                </tr>
              )
            })}


          </tbody>
          </table>
          <UserProfileOpenOrdersModal
            success={this.state.cancelSuccess}
            open={this.state.showModal}
            closeHandler={this.handleClose} />
        </div>
    )
  }
}

export default Orderbook;
