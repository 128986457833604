import React, { Component } from 'react';


class UserProfilePositionsCard extends Component {

  render() {
    return (
      <div className="card " >
        <div className="card-content">
              <nav className="level">
                  <div className="level-item has-text-centered" >
                    <div>
                      <p className="title is-4">{this.props.qName}</p>
                    </div>
                  </div>
                  <div className="level-item has-text-centered" >
                    <div>
                      <p className="heading">Position</p>
                      <p className="title is-4">{this.props.position}</p>
                    </div>
                  </div>
              </nav>
        </div>
      </div>

      
    )
  }
}

export default UserProfilePositionsCard;
