import React, { Component } from 'react';


class UserProfileOpenOrdersCard extends Component {
  constructor(props) {
    super(props)

    // this.handleClick = this.handleClick.bind(this);
  }

  // handleClick() {
  //   this.props.clickHandler(this.props.idx);
  // }

  render() {
    return (
      <div className="card " >
        <div className="card-content">
              <nav className="level">
                  <div className="level-item has-text-centered" >
                    <div>
                      <p className="heading">Price</p>
                      <p className="title is-4">{this.props.price}</p>
                    </div>
                  </div>
                  <div className="level-item has-text-centered" >
                    <div>
                      <p className="heading">Volume</p>
                      <p className="title is-4">{this.props.volume}</p>
                    </div>
                  </div>
                  <div className="level-item has-text-centered" >
                    <div className="button is-danger" onClick={this.props.handleCancelOrder} >
                      Cancel Order
                    </div>
                  </div>
              </nav>
        </div>
      {/* <div class="buttons has-addons">
        <span class="button">{'Price: ' + this.props.price}</span>
        <span class="button">{'Volume: ' + this.props.volume} </span>
        <span class="button is-danger">Cancel</span>
      </div>*/}
      </div>

      
    )
  }
}

export default UserProfileOpenOrdersCard;
