import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import Logo from './../../assets/logo.png';

class HeaderBrand extends Component {
  render() {
    return (
      <div className="navbar-brand is-large">
        <NavLink to="/" activeClassName="onPage" className="navbar-item">
          <img src={Logo} style={{"transform": "scale(1.8)"}} alt="" />
        </NavLink>
        <div className="navbar-burger burger" onClick={this.props.toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
}

export default HeaderBrand;