import React, { Component } from 'react';
import { Link } from "react-router-dom";

// style = {
//   fontSize: '0.5rem'
// }

class HeaderUserControls extends Component {
  render() {
    // console.log(this.props.user.marginAvailable)
    return (
      <div className="navbar-end">
        <div className="navbar-item">
          <Link to="/user" className="navbar-item">
            <div className="columns">
              <div className="column">
                <div className="is-heading " style={{fontSize: '0.6rem'}}> {'Est. Worth'}</div>
                <div className="is-warning is-large has-text-success"> {'$' + this.props.user.netWorth}</div>
              </div>
              <div className="column">
                <div className="is-heading " style={{fontSize: '0.6rem'}}> {'Margin'}</div>
                <div className="is-warning is-large has-text-success"> {'$' + this.props.user.marginAvailable}</div>
              </div>
            </div>
          </Link>
          
        </div>
        <div className="navbar-divider">
        </div>
        <div className="navbar-item has-dropdown is-hoverable">
          <div className="navbar-link">
            {this.props.user.givenName + ' ' + this.props.user.familyName}
          </div>
          <div className="navbar-dropdown">
            <Link to="/user" className="navbar-item">
              <div>                                                                                                               
                <span className="icon is-small">
                  <i className="fa fa-user-circle-o"></i>
                </span>
                My Dashboard
              </div>
            </Link>
            <a className="navbar-item Disabled" onClick={this.props.signOut}>
              <div>
                <span className="icon is-small">
                  <i className="fa fa-sign-out"></i>
                </span>
                Sign Out (disabled)
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderUserControls;
