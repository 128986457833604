import React, { Component } from 'react';
import UserProfileHeading from './UserProfileHeading';
import UserProfileAccountInfo from './UserProfileAccountInfo';
import UserProfilePositions from './UserProfilePositions';
import UserProfileOpenOrders from './UserProfileOpenOrders';
// import UserProfileHistory from './UserProfileHistory';
import './styles/User.css';


class UserProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      body: 'account', // options: account, open, history
      showModal: false,
      accountOpen: false,
      positionsOpen: true,
      ordersOpen: false,
      historyOpen: false
    }

    this.handleAccountClick = this.handleAccountClick.bind(this);
    this.handlePositionsClick = this.handlePositionsClick.bind(this);
    this.handleOrdersClick = this.handleOrdersClick.bind(this);
    this.handleHistoryClick = this.handleHistoryClick.bind(this);
  }

  handleAccountClick() {
    this.setState({ accountOpen: true, positionsOpen: false, ordersOpen: false, historyOpen: false});
  }

  handlePositionsClick() {
    this.setState({ accountOpen: false, positionsOpen: true, ordersOpen: false, historyOpen: false});
  }

  handleOrdersClick() {
    this.setState({ accountOpen: false, positionsOpen: false, ordersOpen: true, historyOpen: false});
  }

  handleHistoryClick() {
    this.setState({ accountOpen: false, positionsOpen: false, ordersOpen: false, historyOpen: true});
  }

  componentDidMount() {
    if (this.props.user.username) {
      // console.log('updating user')
      this.props.userUpdater();
    }
  }


  render() {
    return (
      <section className="section">
        <div className="container">
          <UserProfileHeading user={this.props.user} />
          <div className="tabs is-boxed is-fullwidth">
            <ul>
              <li className={this.state.positionsOpen ? "is-active" : ""} onClick={this.handlePositionsClick}>
                <a>
                  <span>Positions</span>
                </a>
              </li>
              <li className={this.state.ordersOpen ? "is-active" : ""} onClick={this.handleOrdersClick}>
                <a>
                  <span>Open Orders</span>
                </a>
              </li>
              <li className={this.state.accountOpen ? "is-active" : ""} onClick={this.handleAccountClick}>
                <a>
                  <span>Account Info</span>
                </a>
              </li>
              {/* <li className={this.state.historyOpen ? "is-active" : ""} onClick={this.handleHistoryClick}>
                <a>
                  <span>History</span>
                </a>
              </li> */}
            </ul>
          </div>
          {this.state.accountOpen ? <UserProfileAccountInfo user={this.props.user} /> : null}
          {this.state.positionsOpen ? <UserProfilePositions user={this.props.user} /> : null}
          {this.state.ordersOpen ? <UserProfileOpenOrders user={this.props.user} userUpdater={this.props.userUpdater} /> : null}
          { /* {this.state.historyOpen ? <UserProfileHistory user={this.props.user} /> : null} */}
        </div>
      </section>
      
    )
  }
}

export default UserProfile;
